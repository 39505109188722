import React, { Component } from "react";
import Slider from "../../Components/Slider/Loadable";
import { instagramSliderSettings } from "../../Components/Slider/SliderSettings";
import { Icon } from "semantic-ui-react";
import { SectionTitle } from "./style";
import InstagramEmbed from "react-instagram-embed";
import { InstagramBase } from "../../config";

import { connect } from "react-redux";
import { getInstagramPost } from "../../Actions";

class InstagramApi extends Component {
  componentDidMount() {
    this.props.getInstagramPost();
  }
  render() {
    const { instagramDataParams, classes } = this.props;
    const allSettings = { settings: instagramSliderSettings };
    if (instagramDataParams.length === 0) {
      return null;
    }
    return (
      <div className={classes}>
        <SectionTitle>
          <h2 className="titleClasses">
            Instagram <Icon name="instagram" />
          </h2>
        </SectionTitle>
        {instagramDataParams && instagramDataParams.length > 0 ? (
          <Slider {...allSettings}>
            {instagramDataParams &&
              instagramDataParams.map((user, i) => {
                return (
                  <div key={i}>
                    <InstagramEmbed
                      url={InstagramBase + user.node.shortcode}
                      maxWidth={320}
                      hideCaption={true}
                      containerTagName="div"
                      protocol=""
                      injectScript
                      onLoading={() => {}}
                      onSuccess={() => {}}
                      onAfterRender={() => {}}
                      onFailure={() => {}}
                    />
                  </div>
                );
              })}
          </Slider>
        ) : (
          <div className="notFoundContent">
            <span>İçerik Bulunamadı!</span>
          </div>
        )}
      </div>
    );
  }
}
export default connect(
  (state) => {
    return state;
  },
  { getInstagramPost }
)(InstagramApi);
