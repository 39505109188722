import React, { Component } from "react";
import "./sellcar.css";
import axios from "axios";
import { API_BASE } from "../../config";
import { Redirect } from "react-router-dom";

export default class SellCar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToVehicleParts: false,
      isFormValid: true,
      redirecttomain: false,
      vehicleParts: [
        {
          id: 1,
          checked: true,
          value: "",
          rowIndex: 1,
          desc: "Sol Ön Çamurluk",
        },
        { id: 2, checked: true, value: "", rowIndex: 1, desc: "Sol Ön Kapı" },
        {
          id: 3,
          checked: true,
          value: "",
          rowIndex: 1,
          desc: "Sol Arka Çamurlukı",
        },
        { id: 4, checked: true, value: "", rowIndex: 1, desc: "Sol Arka Kapı" },
        {
          id: 5,
          checked: true,
          value: "",
          rowIndex: 1,
          desc: "Sağ Ön Çamurluk",
        },
        { id: 6, checked: true, value: "", rowIndex: 1, desc: "Sağ Ön Kapı" },
        {
          id: 7,
          checked: true,
          value: "",
          rowIndex: 1,
          desc: "Sağ Arka Çamurluk",
        },
        { id: 8, checked: true, value: "", rowIndex: 1, desc: "Sağ Arka Kapı" },
        { id: 9, checked: true, value: "", rowIndex: 1, desc: "Kaput" },
        { id: 10, checked: true, value: "", rowIndex: 1, desc: "Tavan" },
        { id: 11, checked: true, value: "", rowIndex: 1, desc: "Bagaj" },
        { id: 12, checked: true, value: "", rowIndex: 1, desc: "Ön Panel" },
        { id: 12, checked: true, value: "", rowIndex: 1, desc: "Sağ Şasi" },
        { id: 12, checked: true, value: "", rowIndex: 1, desc: "Sol Şasi" },
        { id: 12, checked: true, value: "", rowIndex: 1, desc: "Bagaj Havuzu" },
      ],
      fullName: "",
      phoneNumber: "",
      model: "",
      motor: "",
      marka: "",
      paket: "",
      transmission: "",
      kilometer: "",
      tramer: "",
      mailAdress: "",
      modelName: "",
      exchange: false,
    };
  }

  componentDidMount() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const model = searchParams.get("model");
    const marka = searchParams.get("marka");
    const fullName = searchParams.get("fullName");
    const phoneNumber = searchParams.get("phoneNumber");
    const modelName = searchParams.get("modelName");

    this.setState(
      {
        model: model,
        marka: marka,
        fullName: fullName,
        phoneNumber: phoneNumber,
        modelName: modelName,
      },
      () => {}
    );
  }

  nextPage = () => {
    if (!this.state.fullName) alert("Ad Soyad zorunludur.");
    else if (!this.state.phoneNumber) alert("Telefon numarası zorunludur.");
    else if (!this.state.modelName) alert("Model adı zorunludur.");
    else if (!this.state.mailAdress || this.state.mailAdress.indexOf("@") == -1)
      alert("Geçerli bir mail adresi olması zorunludur.");
    else this.setState({ redirectToVehicleParts: true });
  };

  handleInputChange = (event, fieldName) => {
    const value = event.target.value;
    this.setState({
      [fieldName]: value,
    });
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (index, val, rowIndex) => {
    const updatedVehicleParts = this.state.vehicleParts.map((part, i) => {
      if (i === index) {
        part.value = val;
        part.rowIndex = rowIndex;
        return { ...part, checked: true };
      } else {
        return { ...part };
      }
    });

    const isAtLeastOneSelected = updatedVehicleParts.some(
      (part) => part.checked
    );

    this.setState({
      vehicleParts: updatedVehicleParts,
      isFormValid: true,
    });
  };

  submitForm = () => {
    let data = {
      fullName: this.state.fullName,
      phoneNumber: this.state.phoneNumber,
      model: this.state.model,
      engine: this.state.motor,
      packet: this.state.paket,
      transmission: this.state.transmission,
      kilometer: this.state.kilometer,
      tramer: this.state.tramer,
      marka: this.state.marka,
      mailAdress: this.state.mailAdress,
      exchange: this.state.exchange,
      modelName: this.state.modelName,
      vehicleParts: [],
    };

    for (let i = 0; i <= this.state.vehicleParts.length - 1; i++) {
      console.log(this.state.vehicleParts[i].value);
      if (i >= 11) {
        let val = "";
        if (this.state.vehicleParts[i].value == "") {
          val = "İşlem Yok";
        } else if (this.state.vehicleParts[i].value == "Boyalı") {
          val = "İşlem Var";
        } else if (this.state.vehicleParts[i].value == "İşlem Var") {
          val = "İşlem Var";
        } else {
          val = "İşlem Yok";
        }

        data.vehicleParts.push({
          name: this.state.vehicleParts[i].desc,
          value: val,
        });
      } else {
        data.vehicleParts.push({
          name: this.state.vehicleParts[i].desc,
          value:
            this.state.vehicleParts[i].value == ""
              ? "Orjinal"
              : this.state.vehicleParts[i].value,
        });
      }
    }

    let saveSaleOfferUrl = `${API_BASE}/api/carSaleOffer`;
    axios
      .post(saveSaleOfferUrl, { offer: data })
      .then((res) => {
        console.log("ok");
      })
      .catch((error) => {
        console.error("Error while saving sale offer:", error);
      });

    alert("Aracınızın fiyatını çalışıyoruz ⏳ \n Belirleyeceğimiz fiyatı en kısa zamanda WhatsApp ve Mail yoluyla size ulaştıracağız");
    this.setState({ redirecttomain: true });
  };

  render() {
    if (this.state.redirecttomain) {
      return <Redirect to="/" />;
    }
    return (
      <React.Fragment>
        {!this.state.redirectToVehicleParts ? (
          <div
            className="layout"
            style={{
              border: "10px rgb(181,2,23) solid",
              width: "100%",
              padding: 15,
              marginTop: "5%",
              marginBottom: "3%",
              backgroundColor: "rgba(216,216,216,.3)",
            }}
          >
            <div
              className="formPartDiv"
              style={{
                display: "inline-flex",
                width: "100%",
                justifyContent: "space-between",
                padding: 25,
                margin: 10,
              }}
            >
              <div className="formPartDivInside" style={{ width: "33%" }}>
                <div style={{ display: "grid" }}>
                  <label style={{ fontWeight: "500", fontSize: 18 }}>
                    Ad Soyad
                  </label>
                  <input
                    type="text"
                    name="fullName"
                    placeholder="Ad Soyad"
                    value={this.state.fullName}
                    onChange={this.handleInputChange}
                    style={{
                      borderRadius: 5,
                      fontSize: 18,
                      border: "1px solid gray",
                      padding: 5,
                      color: "black",
                      textIndent: 7,
                      marginLeft: 5,
                    }}
                  />
                </div>
              </div>
              <div className="formPartDivInside" style={{ width: "33%" }}>
                <div style={{ display: "grid" }}>
                  <label style={{ fontWeight: "500", fontSize: 18 }}>
                    Telefon Numarası
                  </label>
                  <input
                    type="text"
                    name="phoneNumber"
                    placeholder="555 XXX XX XX"
                    value={this.state.phoneNumber}
                    onChange={this.handleInputChange}
                    style={{
                      borderRadius: 5,
                      fontSize: 18,
                      border: "1px solid gray",
                      padding: 5,
                      color: "black",
                      textIndent: 7,
                      marginLeft: 5,
                    }}
                  />
                </div>
              </div>
              <div className="formPartDivInside" style={{ width: "33%" }}>
                <div style={{ display: "grid" }}>
                  <label style={{ fontWeight: "500", fontSize: 18 }}>
                    Mail Adresi
                  </label>
                  <input
                    type="text"
                    name="mailAdress"
                    placeholder="Mail Adresi"
                    value={this.state.mailAdress}
                    onChange={this.handleInputChange}
                    style={{
                      borderRadius: 5,
                      fontSize: 18,
                      border: "1px solid gray",
                      padding: 5,
                      color: "black",
                      textIndent: 7,
                      marginLeft: 5,
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className="formPartDiv"
              style={{
                display: "inline-flex",
                width: "100%",
                justifyContent: "space-between",
                padding: 25,
                margin: 10,
              }}
            >
              <div className="formPartDivInside" style={{ width: "33%" }}>
                <div style={{ display: "grid" }}>
                  <label style={{ fontWeight: "500", fontSize: 18 }}>
                    Marka
                  </label>
                  <input
                    type="text"
                    name="marka"
                    placeholder="Marka"
                    value={this.state.marka}
                    onChange={this.handleInputChange}
                    style={{
                      borderRadius: 5,
                      fontSize: 18,
                      border: "1px solid gray",
                      padding: 5,
                      color: "black",
                      textIndent: 7,
                      marginLeft: 5,
                    }}
                  />
                </div>
              </div>
              <div className="formPartDivInside" style={{ width: "33%" }}>
                <div style={{ display: "grid" }}>
                  <label style={{ fontWeight: "500", fontSize: 18 }}>
                    Model Adı
                  </label>
                  <input
                    type="text"
                    name="modelName"
                    placeholder="Örn: Golf"
                    value={this.state.modelName}
                    onChange={this.handleInputChange}
                    style={{
                      borderRadius: 5,
                      fontSize: 18,
                      border: "1px solid gray",
                      padding: 5,
                      color: "black",
                      textIndent: 7,
                      marginLeft: 5,
                    }}
                  />
                </div>
              </div>
              <div className="formPartDivInside" style={{ width: "33%" }}>
                <div style={{ display: "grid" }}>
                  <label style={{ fontWeight: "500", fontSize: 18 }}>
                    Model Yılı
                  </label>
                  <select
                    name="model"
                    value={this.state.model}
                    onChange={this.handleInputChange}
                    style={{
                      borderRadius: 5,
                      fontSize: 18,
                      border: "1px solid gray",
                      padding: 5,
                      color: "black",
                      textIndent: 7,
                      marginLeft: 5,
                    }}
                  >
                    <option value="" disabled>
                      Model Seçiniz
                    </option>
                    {Array.from(
                      { length: new Date().getFullYear() - 1985 + 1 },
                      (_, index) => (
                        <option
                          key={index}
                          value={new Date().getFullYear() - index}
                        >
                          {new Date().getFullYear() - index}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
            </div>
            <div
              className="formPartDiv"
              style={{
                display: "inline-flex",
                width: "100%",
                justifyContent: "space-between",
                padding: 25,
                margin: 10,
              }}
            >
              <div className="formPartDivInside" style={{ width: "33%" }}>
                <div style={{ display: "grid" }}>
                  <label style={{ fontWeight: "500", fontSize: 18 }}>
                    Motor
                  </label>
                  <input
                    type="text"
                    name="motor"
                    placeholder="Örn: 1.5 Dci"
                    value={this.state.motor}
                    onChange={this.handleInputChange}
                    style={{
                      borderRadius: 5,
                      fontSize: 18,
                      border: "1px solid gray",
                      padding: 5,
                      color: "black",
                      textIndent: 7,
                      marginLeft: 5,
                    }}
                  />
                </div>
              </div>
              <div className="formPartDivInside" style={{ width: "33%" }}>
                <div style={{ display: "grid" }}>
                  <label style={{ fontWeight: "500", fontSize: 18 }}>
                    Paket
                  </label>
                  <input
                    type="text"
                    name="paket"
                    placeholder="Örn: İcon Paket"
                    value={this.state.paket}
                    onChange={this.handleInputChange}
                    style={{
                      borderRadius: 5,
                      fontSize: 18,
                      border: "1px solid gray",
                      padding: 5,
                      color: "black",
                      textIndent: 7,
                      marginLeft: 5,
                    }}
                  />
                </div>
              </div>
              <div className="formPartDivInside" style={{ width: "33%" }}>
                <div style={{ display: "grid" }}>
                  <label style={{ fontWeight: "500", fontSize: 18 }}>
                    Vites
                  </label>
                  <select
                    name="transmission"
                    value={this.state.transmission}
                    onChange={this.handleInputChange}
                    style={{
                      borderRadius: 5,
                      fontSize: 18,
                      border: "1px solid gray",
                      padding: 5,
                      color: "gray",
                      textIndent: 7,
                      marginLeft: 5,
                    }}
                  >
                    <option value="" disabled>
                      Seçiniz
                    </option>
                    <option value="manuel">Manuel</option>
                    <option value="otomatik">Otomatik</option>
                  </select>
                </div>
              </div>
            </div>
            <div
              className="formPartDiv"
              style={{
                display: "inline-flex",
                width: "100%",
                justifyContent: "space-between",
                padding: 25,
                margin: 10,
              }}
            >
              <div className="formPartDivInside" style={{ width: "33%" }}>
                <div style={{ display: "grid" }}>
                  <label style={{ fontWeight: "500", fontSize: 18 }}>
                    Kilometre
                  </label>
                  <input
                    type="text"
                    name="kilometer"
                    placeholder="Kilometre KM"
                    value={this.state.kilometer}
                    onChange={this.handleInputChange}
                    style={{
                      borderRadius: 5,
                      fontSize: 18,
                      border: "1px solid gray",
                      padding: 5,
                      color: "black",
                      textIndent: 7,
                      marginLeft: 5,
                    }}
                  />
                </div>
              </div>
              <div className="formPartDivInside" style={{ width: "33%" }}>
                <div style={{ display: "grid" }}>
                  <label style={{ fontWeight: "500", fontSize: 18 }}>
                    Tramer
                  </label>
                  <input
                    type="text"
                    name="tramer"
                    placeholder="Toplam Tramer Kaydı"
                    value={this.state.tramer}
                    onChange={this.handleInputChange}
                    style={{
                      borderRadius: 5,
                      fontSize: 18,
                      border: "1px solid gray",
                      padding: 5,
                      color: "black",
                      textIndent: 7,
                      marginLeft: 5,
                    }}
                  />
                </div>
              </div>
              <div
                className="formPartDivInside"
                style={{ width: "33%", marginLeft: "5%" }}
              >
                <div style={{ display: "grid" }}>
                  <label style={{ fontWeight: "500", fontSize: 18 }}>
                    Takas İstiyorum
                  </label>
                  <input
                    type="checkbox"
                    name="exchange"
                    value={this.state.exchange}
                    onChange={() =>
                      this.setState({
                        exchange: !this.state.exchange,
                      })
                    }
                    style={{
                      borderRadius: 5,
                      fontSize: 18,
                      border: "1px solid gray",
                      padding: 5,
                      color: "black",
                      height: 25,
                      width: 25,
                    }}
                  />
                </div>
              </div>
              <div className="formPartDivInside" style={{ width: "33%" }}></div>
            </div>
            <div style={{ width: "100%", padding: 10, marginBottom: 50 }}>
              <button
                className="formBtn"
                disabled={!this.state.isFormValid}
                onClick={() => this.nextPage()}
                style={{
                  margin: "0 auto",
                  padding: 15,
                  width: "150px",
                  border: "none",
                  backgroundColor: this.state.isFormValid
                    ? "rgb(181,2,23)"
                    : "gray",
                  color: "white",
                  borderRadius: 2,
                  fontSize: 20,
                  height: 75,
                }}
              >
                DEVAM
              </button>
            </div>
          </div>
        ) : (
          <div
            className="layout"
            style={{
              border: "10px rgb(181,2,23) solid",
              backgroundColor: "rgba(216,216,216,.3)",
              padding: 15,
              margin: "0 auto",
              marginTop: 25,
              marginBottom: 25,
            }}
          >
            <h2 style={{ margin: 25, marginBottom: 35 }}>
              Boya ve Değişen/Ekspertiz Tanımları
            </h2>
            <table
              className="vehicleparts"
              style={{ width: "80%", padding: 15, margin: 25 }}
            >
              <thead>
                <tr>
                  <th></th>
                  <th>Orjinal</th>
                  <th>Boyalı</th>
                  <th>Değişen</th>
                </tr>
              </thead>
              <tbody>
                {this.state.vehicleParts.map((part, index) =>
                  index === 11 ? (
                    <React.Fragment key={index}>
                      <tr style={{ textAlign: "center" }}>
                        <td className="headintr">
                          ÖN PANEL, ŞASİ ve BAGAJ HAVUZU
                        </td>
                      </tr>
                      <tr style={{ border: "none" }}>
                        <td></td>
                        <td style={{ fontWeight: "bold" }}>İşlem Yok</td>
                        <td style={{ fontWeight: "bold" }}>İşlem Var</td>
                      </tr>
                      <tr key={index}>
                        <td>{part.desc}</td>
                        <td>
                          <input
                            type="checkbox"
                            checked={part.checked && part.rowIndex === 1}
                            onChange={() =>
                              this.handleCheckboxChange(index, "İşlem Yok", 1)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            checked={part.checked && part.rowIndex === 2}
                            onChange={() =>
                              this.handleCheckboxChange(index, "İşlem Var", 2)
                            }
                          />
                        </td>
                      </tr>
                    </React.Fragment>
                  ) : (
                    <tr key={index}>
                      <td>{part.desc}</td>
                      <td>
                        <input
                          type="checkbox"
                          checked={part.checked && part.rowIndex === 1}
                          onChange={() =>
                            this.handleCheckboxChange(index, "Orjinal", 1)
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          checked={part.checked && part.rowIndex === 2}
                          onChange={() =>
                            this.handleCheckboxChange(index, "Boyalı", 2)
                          }
                        />
                      </td>
                      {index < 11 ? (
                        <td>
                          <input
                            type="checkbox"
                            checked={part.checked && part.rowIndex === 3}
                            onChange={() =>
                              this.handleCheckboxChange(index, "Değişen", 3)
                            }
                          />
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  )
                )}
              </tbody>
            </table>
            <div
              className="formBtn"
              style={{ width: "100%", padding: 10, marginBottom: 50 }}
            >
              <button
                onClick={() => this.submitForm()}
                style={{
                  margin: "0 auto",
                  padding: 15,
                  width: "150px",
                  border: "none",
                  backgroundColor: "rgb(181,2,23)",
                  color: "white",
                  borderRadius: 2,
                  fontSize: 20,
                  height: 75,
                }}
              >
                GÖNDER
              </button>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
