import styled from 'styled-components'

const PaymentContainer = styled.div`
width: 100%;
max-width: 1200px;
margin: auto;
`;

const Image = styled.div`
    img{
        width: 100%;
        max-height: 300px;
    }
`;

const Heading = styled.div`
    margin-top: 20px;
    padding: 0 20px;
    span{
        font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin-right: 10px;
    color: #4e4e4e;
    }
`;

const PaymentContent = styled.div`
    margin: 20px 0;
    .ui.accordion .accordion .active.content, .ui.accordion .active.content{
        background-color: whitesmoke;
        padding: 25px;
    }
`;

export { PaymentContainer, Image, Heading, PaymentContent }