import styled from 'styled-components'

const FancyBoxContainer = styled.div `
border:1px #ededed solid;
border-radius:5px;
overflow:hidden;
max-width:550px;
width:50%;
.ui.placeholder{
    width:100%;
    height:100%;
    max-width: 100%;
}
@media screen and (min-width: 280px) and (max-width:800px){
    max-width: 100%;
    width: 100%;
}
`;

const ThumbNails = styled.div `
    a{
        img{
            width:100px;
        }
    }
`;

const BigImage = styled.div `
    a{
        img{
            max-width:100%;
        }
    }
`;

export { FancyBoxContainer, ThumbNails, BigImage };