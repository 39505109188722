import styled from 'styled-components'


const SectionTitle = styled.div `
color: #484848;
font-size:18px;
margin-bottom: 20px;
padding-left: 10px;
@media screen and (min-width: 280px) and (max-width:800px){
    padding-left: 5px;
    }
`;


export { SectionTitle };