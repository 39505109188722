import styled from 'styled-components'

const SectionTitle = styled.div `
color: #484848;
font-size:18px;
/* margin-bottom: 20px; */
padding: 0 10px;
display: flex;
justify-content: space-between;
align-items:center;
color:#484848;
    h2{
        margin:0;
    }
    a{
        display: flex;
        color: #676767;
        border: 1px #ededed solid;
        padding: 10px;
        border-radius: 5px;
        transition:.5s ease;
        align-items:center;

            &:hover{
                background-color: #C4111E;
                color: #fff;
            }
    }
    @media screen and (min-width: 280px) and (max-width:800px){
        padding: 0 5px;
        margin-bottom: 10px;
    }
`;

export { SectionTitle };