const INITIAL_STATE = {
  allCarParams: [],
  instagramDataParams:[],
  carDetailsData:{},
  carDetailsDataImages: [],
  aboutParams: {},
  updateAboutParams: {},
  servicesParam: [],
  contactParam: {}
  };
  
  export default (state = INITIAL_STATE, action) => {
          switch (action.type) {      
          case 'ALLCARDATATYPE':
          {
            return {...state, 
              allCarParams: action.allCarParams.filter( item => item.published !== false )
            } 
          }                             
          case 'INSTAGRAMDATA':
          {
            return {...state,instagramDataParams:action.instagramDataParams} 
          }      
          case 'SERVICES':
            {
              return {...state,
                formResultData:{
                  success: action.successData,
                  result: action.formResultData
                }} 
            }                       
          case 'CONTACT':
            {
              return {...state,
                contactFormResultData:{
                  success: action.successContactData,
                  result: action.contactFormResultData
                }} 
            }                       
          case 'GETABOUT':
          {
            return {...state,aboutParams:action.aboutParams} 
          }                             
          case 'UPDATEABOUT':
          {
            return {...state,updateAboutParams:action.updateAboutParams} 
          }                             
          case 'CARDETAILS':
          {
            return {...state,
              carDetailsData:action.carDetailsData,
              carDetailsDataImages: action.carDetailsDataImages
            } 
          }                             
          default :
          return {...state};    
      }
    };