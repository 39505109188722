import React, { Component } from "react";
import Header from "./Components/Header/Loadable";
import Footer from "./Components/Footer/Loadable";
import Homepage from "./Container/Homepage";
import Details from "./Container/Details";
import AllCars from "./Container/AllCars";
import Services from "./Container/Services";
import About from "./Container/About";
import MobilMenu from "./Components/MobileMenu/Loadable";
import Contact from "./Container/Contact";
import { Row } from "react-bootstrap";
import { Container } from "react-bootstrap";
import Payment from "./Container/PaymentService";

import { BrowserRouter, Redirect, Switch, Route, Link } from "react-router-dom";
import SellCar from "./Container/SellCar";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuControl: true,
    };
    this.mobileMenuClick = this.mobileMenuClick.bind(this);
  }

  mobileMenuClick(e) {
    e.preventDefault();
    const { menuControl } = this.state;
    this.setState({
      menuControl: !menuControl,
    });
  }
  render() {
    const { menuControl } = this.state;
    return (
      <React.Fragment>
        <BrowserRouter>
          <Container
            className="header navbar navbar-expand navbar-dark pt-0 pb-0 flex-column flex-md-row"
            style={{ minHeight: "5rem", border: "1px #ededed solid" }}
            fluid
          >
            <div
              className="mobilMenuCloseIcon menuIcon"
              onClick={this.mobileMenuClick}
            >
              <div className="nav-icon">
                <div></div>
              </div>
            </div>
            <Header />
          </Container>
          <section className="container-fluid pb-0 mb-0">
            <Row>
              <main
                className="col-12 col-md-12 col-xl-12 py-md-12 pl-md-12 bd-content p-0 bd-masthead"
                role="main"
              >
                {!menuControl && (
                  <React.Fragment>
                    <div
                      className="mobilMenuCloseIcon"
                      onClick={this.mobileMenuClick}
                    >
                      x
                    </div>
                    <div className="mobileMenu">
                      <MobilMenu>
                        <li onClick={this.mobileMenuClick}>
                          <Link to="/" target="_self">
                            Anasayfa
                          </Link>
                        </li>
                        <li onClick={this.mobileMenuClick}>
                          <Link to="/tum-araclar" target="_self">
                            Tüm Araçlar
                          </Link>
                        </li>
                        <li onClick={this.mobileMenuClick}>
                          <Link to="/hizmetlerimiz" target="_self">
                            Hizmetlerimiz
                          </Link>
                        </li>
                        <li onClick={this.mobileMenuClick}>
                          <Link to="/odeme-secenekleri" target="_self">
                            Ödeme Seçenekleri
                          </Link>
                        </li>
                        <li onClick={this.mobileMenuClick}>
                          <Link to="/hakkimizda" target="_self">
                            Hakkımızda
                          </Link>
                        </li>
                        <li onClick={this.mobileMenuClick}>
                          <Link to="/iletisim" target="_self">
                            İletişim
                          </Link>
                        </li>
                      </MobilMenu>
                    </div>
                  </React.Fragment>
                )}
                <Switch>
                  <Route exact path="/" component={Homepage} />
                  <Route exact path="/tum-araclar" component={AllCars} />
                  <Route exact path="/arac-detay/:id" component={Details} />
                  <Route exact path="/hizmetlerimiz" component={Services} />
                  <Route exact path="/hakkimizda" component={About} />
                  <Route exact path="/odeme-secenekleri" component={Payment} />
                  <Route exact path="/iletisim" component={Contact} />
                  <Route exact path="/arac-sat" component={SellCar} />
                  <Route render={() => <Redirect to="/" />} />
                </Switch>
              </main>
            </Row>
          </section>
          <footer>
            <Footer />
          </footer>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}
