import React, { Component } from 'react'
import ProcessComponent from '../../Components/Process/Loadable'
import { AreaTitle } from './style'
import { Icon } from 'semantic-ui-react'

export default class Process extends Component {
    render() {
        return (
            <React.Fragment>
                <AreaTitle className="layout">
                    <h2 className="titleClasses">Hizmetlerimiz <Icon name='bullhorn' /></h2>
                </AreaTitle>
                <ProcessComponent/>
            </React.Fragment>
        )
    }
}
