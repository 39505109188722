import React, { Component } from "react";
import { Redirect } from "react-router-dom";

export default class Offer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: 1,
      redirectToAllCars: false,
      redirectToSellCar: false,
      marka: "",
      model: "",
      fullName: "",
      phoneNumber: "",
      modelName: "",
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  getBackground(x) {
    return this.state.selection === x ? "rgb(181,2,23)" : "black";
  }

  submitForm() {
    if (this.state.selection === 1) {
      if (!this.state.fullName) alert("Ad Soyad zorunludur.");
      else if (!this.state.phoneNumber) alert("Telefon numarası zorunludur.");
      else
        this.setState({
          redirectToSellCar: this.state.selection === 1,
          redirectToAllCars: this.state.selection !== 1,
        });
    } else this.setState({ redirectToAllCars: true });
  }

  render() {
    if (this.state.redirectToAllCars) {
      return <Redirect to="/tum-araclar" />;
    }

    if (this.state.redirectToSellCar) {
      let a =
        "/arac-sat?model=" +
        this.state.model +
        "&marka=" +
        this.state.marka +
        "&fullName=" +
        this.state.fullName +
        "&phoneNumber=" +
        this.state.phoneNumber +
        "&modelName=" +
        this.state.modelName;
      return <Redirect to={a} />;
    }

    return (
      <div className="layout" style={{ marginBottom: "40px" }}>
        <div className="main" style={{ display: "flex", margin: "0 auto" }}>
          <div style={{ margin: "0 auto" }}>
            <button
              onClick={() => this.setState({ selection: 1 })}
              style={{
                fontSize: 15,
                padding: 20,
                borderRadius: 100,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                border: "none",
                backgroundColor: this.getBackground(1),
                color: "white",
                minWidth: 175,
                minHeight: 50,
                fontWeight: "bold",
              }}
            >
              ARAÇ SAT
            </button>
            <button
              onClick={() => this.setState({ selection: 2 })}
              style={{
                fontSize: 15,
                padding: 20,
                borderRadius: 100,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                border: "none",
                backgroundColor: this.getBackground(2),
                color: "white",
                minWidth: 175,
                minHeight: 50,
                fontWeight: "bold",
              }}
            >
              ARAÇ AL
            </button>
          </div>
        </div>

        <div
          style={{
            margin: "0 auto",
            width: "100%",
            marginTop: "25px",
            backgroundColor: "#f6f6f6",
            padding: "30px",
            paddingLeft: "18%",
          }}
        >
          {this.state.selection === 1 ? (
            <>
              <h3>ARAÇ SAT</h3>
              <p>
                Arabanızın piyasa değerini hesaplıyor ve aynı gün içinde araç
                bedelinizi hesabınıza yatırıyoruz.
              </p>
            </>
          ) : (
            <>
              <h3>ARAÇ AL</h3>
              <p>
                Onlarca markanın yüzlerce model exper raporlu araçlarını uygun
                fiyatlara satın alabilirsiniz.
              </p>
            </>
          )}
          <div
            style={{
              margin: "0 auto",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            {this.state.selection === 1 && (
              <>
                <select
                  name="marka"
                  value={this.state.marka}
                  onChange={this.handleInputChange}
                  style={{
                    borderRadius: 5,
                    fontSize: 18,
                    width: "80%",
                    border: "1px solid gray",
                    padding: 5,
                    marginTop: 15,
                    color: "black",
                    textIndent: 7,
                  }}
                >
                  <option value="" disabled>
                    Marka Seçiniz
                  </option>
                  <option value="Aston Martin">Aston Martin</option>
                  <option value="Audi">Audi</option>
                  <option value="Bentley">Bentley</option>
                  <option value="BMW">BMW</option>
                  <option value="Bugatti">Bugatti</option>
                  <option value="Buick">Buick</option>
                  <option value="Cadillac">Cadillac</option>
                  <option value="Chevrolet">Chevrolet</option>
                  <option value="Chrysler">Chrysler</option>
                  <option value="Citroen">Citroen</option>
                  <option value="Dodge">Dodge</option>
                  <option value="Ferrari">Ferrari</option>
                  <option value="Fiat">Fiat</option>
                  <option value="Ford">Ford</option>                  
                  <option value="Opel">Opel</option>                  
                  <option value="GMC">GMC</option>
                  <option value="Honda">Honda</option>
                  <option value="Hummer">Hummer</option>
                  <option value="Hyundai">Hyundai</option>
                  <option value="Infiniti">Infiniti</option>
                  <option value="Jaguar">Jaguar</option>
                  <option value="Jeep">Jeep</option>
                  <option value="Kia">Kia</option>
                  <option value="Lamborghini">Lamborghini</option>
                  <option value="Land Rover">Land Rover</option>
                  <option value="Lexus">Lexus</option>
                  <option value="Lincoln">Lincoln</option>
                  <option value="Maserati">Maserati</option>
                  <option value="Mazda">Mazda</option>
                  <option value="McLaren">McLaren</option>
                  <option value="Mercedes">Mercedes</option>
                  <option value="Mini">Mini</option>
                  <option value="Nissan">Nissan</option>
                  <option value="Peugeot">Peugeot</option>
                  <option value="Porsche">Porsche</option>
                  <option value="Renault">Renault</option>
                  <option value="Rolls-Royce">Rolls-Royce</option>
                  <option value="Subaru">Subaru</option>
                  <option value="Suzuki">Suzuki</option>
                  <option value="Tesla">Tesla</option>
                  <option value="Toyota">Toyota</option>
                  <option value="Volkswagen">Volkswagen</option>
                  <option value="Volvo">Volvo</option>
                  <option value="Diğer Marka">Diğer</option>
                </select>
                <br />
                <input
                  type="text"
                  name="modelName"
                  placeholder="Model: Örn. Golf"
                  value={this.state.modelName}
                  onChange={this.handleInputChange}
                  style={{
                    borderRadius: 5,
                    fontSize: 18,
                    width: "80%",
                    border: "1px solid gray",
                    padding: 5,
                    marginTop: 15,
                    textIndent: 7,
                  }}
                />
                <select
                  name="model"
                  value={this.state.model}
                  onChange={this.handleInputChange}
                  style={{
                    borderRadius: 5,
                    fontSize: 18,
                    width: "80%",
                    border: "1px solid gray",
                    padding: 5,
                    marginTop: 15,
                    color: "black",
                    textIndent: 7,
                  }}
                >
                  <option value="" disabled>
                    Model Yılı Seçiniz
                  </option>
                  {Array.from(
                    { length: new Date().getFullYear() - 1985 + 1 },
                    (_, index) => (
                      <option
                        key={index}
                        value={new Date().getFullYear() - index}
                      >
                        {new Date().getFullYear() - index}
                      </option>
                    )
                  )}
                </select>
                <br />
                <input
                  type="text"
                  name="fullName"
                  placeholder="Ad Soyad"
                  value={this.state.fullName}
                  onChange={this.handleInputChange}
                  style={{
                    borderRadius: 5,
                    fontSize: 18,
                    width: "80%",
                    border: "1px solid gray",
                    padding: 5,
                    marginTop: 15,
                    textIndent: 7,
                  }}
                />
                <br />
                <input
                  type="text"
                  name="phoneNumber"
                  placeholder="Tel No: 555 XXX XX XX"
                  value={this.state.phoneNumber}
                  onChange={this.handleInputChange}
                  style={{
                    borderRadius: 5,
                    fontSize: 18,
                    width: "80%",
                    border: "1px solid gray",
                    padding: 5,
                    marginTop: 15,
                    color: "black",
                    textIndent: 7,
                  }}
                />
                <br />
              </>
            )}

            <br />
            <button
              onClick={() => this.submitForm()}
              style={{
                margin: "0 auto",
                marginTop: 15,
                border: "none",
                width: "80%",
                height: "50px",
                fontSize: 18,
                fontWeight: "bold",
                color: "white",
                backgroundColor:
                  this.state.selection === 1 ? "rgb(181,2,23)" : "black",
                borderRadius: 5,
              }}
            >
              {this.state.selection === 1 ? "Arabamı Değerle" : "Aracını Bul"}
            </button>

            <img
              src={
                this.state.selection === 1
                  ? "https://test.borzmotor.com/1.png"
                  : "https://test.borzmotor.com/2.png"
              }
              style={{
                maxHeight: "200px",
                width: "50%",
                margin: "5%",
                marginLeft: "13%",
                marginTop: "7%",
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
