import React, { Component } from 'react'
import ServicesComponent from '../../Components/Services/Loadable'
import Swal from 'sweetalert2'

import {connect} from 'react-redux';
import { setCarServices } from "../../Actions";

class Services extends Component {

    constructor(props){
        super(props);
        this.state={
            sellingType: null,
            brand: '',
            model: '',
            year: '',
            kilometers: '',
            fuel: '',
            gear: '',
            paintPiece: '',
            changingTrack: '',
            accident: '',
            nameSurname: '',
            phone: '',
            email: '',
            message: '',
        }
        this.formChange = this.formChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.successForm = this.successForm.bind(this);
        this.selectForm = this.selectForm.bind(this);
        this.selectDescriptionInside = this.selectDescriptionInside.bind(this);
    }

    formChange(e){
        const target = e.target;
        const name = target.name;
        const value = target.value;

        this.setState({
            [name]: value
        });
    }

    submitForm(){
        const values = this.state;
        this.props.setCarServices(values)
    }

    successForm(){
            Swal.fire(
                'Başarılı',
                'Mesajınız Bize Ulaştı',
                'success'
            )
            setTimeout(() => {
                window.location.reload()
            }, 1500);
    }

    selectForm(e){
        const target = e;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    selectDescriptionInside(){
        const { sellingType } = this.state;
        if(sellingType === "Araç Satmak İstiyorum"){
            return "Aracınızı anında nakite çevirmek artık çok kolay! Aşağıdaki formu doldurun, konusunda uzman ekibimiz aracınıza ortalama değer biçsin. Ön fiyat tespitinden sonra son fiyat tespiti ve ön ekspertiz için sizleri showroomumuza bekliyoruz."
        }else if(sellingType === "Konsinye Bırakmak İstiyorum"){
            return "Şehrin yoğunluğunda aracınızı satmak, ekspertiz ve noter işlemlerine vakit harcamak istemiyorsanız; Borz Motor bu işlemi sizin adınıza yapar."
        }else{
            return "Şehrin yoğunluğunda aracınızı satmak, ekspertiz ve noter işlemlerine vakit harcamak istemiyorsanız; Borz Motor bu işlemi sizin adınıza yapar. Aşağıdaki formu doldurun ve aracınızı sözleşme karşılığı konsinye bırakın. 1 haftada satış garantisi BORZ MOTOR’DA!"
        }
    }

    render() {
        const {
            brand, model, year, kilometers, fuel, gear, paintPiece, changingTrack, accident, nameSurname, phone, email, message
        } = this.state;
        const sellingTypeValues = [
            { value: 'Araç Satmak İstiyorum', label: 'Araç Satmak İstiyorum', name: 'sellingType' },
            { value: 'Konsinye Bırakmak İstiyorum', label: 'Konsinye Bırakmak İstiyorum', name: 'sellingType' },
          ];
        if(this.props.formResultData === undefined){
            return (
                <ServicesComponent 
                selectDescription={ this.selectDescriptionInside() }
                selectName="sellingType"
                selectChange={(e) => this.selectForm(e)}
                selectOptions={sellingTypeValues}
                brand={brand}
                model={model}
                year={year}
                kilometers={kilometers}
                fuel={fuel}
                gear={gear}
                paintPiece={paintPiece}
                changingTrack={changingTrack}
                accident={accident}
                nameSurname={nameSurname}
                phone={phone}
                email={email}
                message={message}
                changed={this.formChange}
                submit={this.submitForm}>
                </ServicesComponent>
            )
        }else{
            return (
                <React.Fragment>
                    { this.successForm() }
                </React.Fragment>
            );
        }
        
    }
}
export default connect((state)=>{return state},{ setCarServices })( Services )
