/* eslint-disable no-useless-constructor */
import React, { Component } from "react";
import VehicleCard from "../../Components/VehicleCard/Loadable";
import Slider from "../../Components/Slider/Loadable";
import { SectionTitle } from "./style";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { sliderSettings } from "../../Components/Slider/SliderSettings";
import placeholderImage from "../../Images/placeholder-single.png";
import { API_BASE } from "../../config";
import { Card, Placeholder } from "semantic-ui-react";
import { connect } from "react-redux";
import { getAllCars } from "../../Actions";

class Vehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carList: false,
      isHomePage: true,
    };
    this.list = this.list.bind(this);
  }

  componentDidMount() {
    this.props.getAllCars();
  }

  list() {
    const { carList } = this.state;
    this.setState({ carList: !carList });
  }

  render() {
    const { allCarParams, classes } = this.props;
    const { carList, isHomePage } = this.state;
    const allSettings = { settings: sliderSettings };
    return (
      <div style={{ marginBottom: "40px" }} className={classes}>
        <SectionTitle>
          <h2 className="titleClasses">
            Son Eklenenler <Icon name="attach" />
          </h2>
          <Link to="/tum-araclar" target="_self">
            Tümünü Gör
          </Link>
        </SectionTitle>
        {allCarParams && allCarParams.length > 0 ? (
          <Slider {...allSettings}>
            {allCarParams &&
              allCarParams
                .slice(0, 12)
                .map(
                  ({
                    _id,
                    brand,
                    published,
                    model,
                    kilometers,
                    price,
                    fuel,
                    gear,
                    intro,
                    images,
                  }) => (
                    <div key={_id}>
                      <VehicleCard
                        target="_self"
                        id={_id}
                        isHomePage={isHomePage}
                        filterClass={carList && carList ? "filtered" : ""}
                        listViewImage={
                          carList && carList ? "filtered" : "noFiltered"
                        }
                        published={
                          published && published
                            ? "İlan Yayında"
                            : "İlan Yayında Değil"
                        }
                        brand={brand}
                        model={model}
                        intro={intro}
                        price={price}
                        km={kilometers}
                        fuel={fuel}
                        gear={gear}
                        href={"/arac-detay/" + _id}
                        images={
                          images && images.length > 0 ? (
                            <div>
                              <img
                                src={API_BASE + "/" + images[0].name}
                                alt="araç görseli"
                              />
                            </div>
                          ) : (
                            <div>
                              <img src={placeholderImage} alt="" />
                            </div>
                          )
                        }
                      />
                    </div>
                  )
                )}
          </Slider>
        ) : (
          <Card.Group itemsPerRow={4}>
            <Card>
              <Card.Content>
                <Placeholder>
                  <Placeholder.Image square />
                </Placeholder>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Placeholder>
                  <Placeholder.Image square />
                </Placeholder>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Placeholder>
                  <Placeholder.Image square />
                </Placeholder>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Placeholder>
                  <Placeholder.Image square />
                </Placeholder>
              </Card.Content>
            </Card>
          </Card.Group>
        )}
      </div>
    );
  }
}
export default connect(
  (state) => {
    return state;
  },
  { getAllCars }
)(Vehicle);
{
  /* <div className="notFoundContent">
            <span>İçerik Bulunamadı!</span>
          </div> */
}
