import React, { Component } from "react";
/* import Filter from '../../Components/Filter/Loadable'
import { Options } from './Options' */
import { isMobile, isTablet } from "react-device-detect";
export default class GetFilter extends Component {
  state = {
    selectedOption: null,
    bg: isMobile || isTablet ? false : true,
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };
  render() {
    const { bg } = this.state;
    return (
      bg && (
        <section className="bgImage">
          {/* <h1 id="publicTitle">Çok farklı bir galeri <br/> <strong style={{color: '#bd052c', letterSpacing: '7px', fontSize: '25px', paddingLeft: '9px' }}>BORZMOTOR</strong></h1> */}
          <div className="borzplus row">
            <div className="borzplusInfo col-md-6">
              <h5>Modern Ekipman</h5>
              <span>Arabanızın Kuaförü</span>
            </div>
            <div className="borzplusCta col-md-6">
              <span>BorzPlus.com</span>
              <a
                href="https://borzplus.com"
                target="_blank"
                rel="noopener noreferrer"
                alt="Borzplus.com"
              >
                keşfet
              </a>
            </div>
          </div>
        </section>
      )
    );
  }
}
