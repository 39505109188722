import React from 'react'
import { FancyBoxContainer } from './style'
//import { Placeholder } from 'semantic-ui-react'
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/scss/image-gallery.scss";

function Fancy({ images }) {
      return (
        <FancyBoxContainer>
          <ImageGallery items={images}/>
        </FancyBoxContainer>
    )}
export default Fancy