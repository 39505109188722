import styled from 'styled-components'

const AreaTitle = styled.div `
margin-bottom:30px;
padding-left: 10px;
color: #484848;
font-size: 18px;
@media screen and (min-width: 280px) and (max-width:800px){
    padding-left: 5px;
    }
`;

export { AreaTitle };