import React, { Component } from 'react'
//import PaymentMethod from '../../Components/PaymentMethod/Loadable'
//import Breadcrumb from '../../Components/BreadCrumb/Loadable'
import banner from '../../Images/banner.jpg'
import { PaymentContainer, Image, Heading, PaymentContent } from './style'
import { Accordion, Icon } from 'semantic-ui-react'

export default class Payment extends Component {

  state = { activeIndex: 0 }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state

    return (
      <React.Fragment>
        <Image>
          <img src={banner} alt="" />
        </Image>
        <PaymentContainer>
          <Heading>
            <span>Ödeme Seçenekleri</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 21.439 13">
              <path id="chevron-down-solid" d="M15.543,136.15,6,126.616a1.177,1.177,0,0,1,0-1.665l1.113-1.112a1.179,1.179,0,0,1,1.664,0l7.6,7.555,7.6-7.555a1.179,1.179,0,0,1,1.664,0l1.113,1.112a1.177,1.177,0,0,1,0,1.665l-9.541,9.533A1.179,1.179,0,0,1,15.543,136.15Z" transform="translate(-5.656 -123.495)" fill="#bd062c" />
            </svg>
          </Heading>

          <PaymentContent>
            <Accordion fluid styled>
              <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={this.handleClick}
              >
                <Icon name='dropdown' />
          Taşıt Kredisi
        </Accordion.Title>
              <Accordion.Content active={activeIndex === 0}>
                <strong style={{ display: 'block', marginBottom: '10px' }}>Banka Kredi Koşulları</strong>
                <p>
                  Borz Motor‘da banka şubesine gitmeksizin kimliğinizle 5 dakikada krediniz hazır! Üstelik 48 aya kadar vade imkanıyla.
                  Kefil yok, sıra beklemek yok! Siz ofisimizde kahvenizi içerken aracınız anında teslim!
          </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 1}
                index={1}
                onClick={this.handleClick}
              >
                <Icon name='dropdown' />
          Senet İle Satış
        </Accordion.Title>
              <Accordion.Content active={activeIndex === 1}>
                <strong style={{ display: 'block', marginBottom: '10px' }}>Senet İle Satış Koşulları</strong>
                <div>
                  Borz Motor, banka kredibilitesi yetersiz müşterilerimize Wowoo ortaklığıyla 24-30-36 aya kadar elden senet ile satış imkanı sunmaktadır. Bunun için yapmanız
                  gereken kimliğinizle Borz Motor’a gelmek. Kefil, banka kredisi yok! Aracınızın satışını yaparak hemen teslim ediyoruz.
                  <br />
                  <strong style={{ display: 'block', margin: '10px 0' }}>Koşullar:</strong>

                  <ul style={{ paddingLeft: '40px' }}>
                    <li style={{ listStyleType: 'disc' }}> %30 peşinat, kalanını 24-30-36 ay vade!</li>
                    <li style={{ listStyleType: 'disc' }}> Vade farkı (faiz) 1.80 ile 2.5 arası değişkenlik gösterir (Detaylı hesaplama sadece ofisimizde).</li>
                    <li style={{ listStyleType: 'disc' }}> En fazla 6 yaşındaki araçlarımıza senetli satış imkanı sağlanmaktadır.</li>
                    <li style={{ listStyleType: 'disc' }}> Kilometre sınırı 130.000 km’dir.</li>
                    <li style={{ listStyleType: 'disc' }}> Kasko ve trafik sigortası zorunludur.</li>
                    <li style={{ listStyleType: 'disc' }}> Şirket adına senetli satış imkanı bulunmamakta, sadece şahıs adına bulunmaktadır.</li>
                    <li style={{ listStyleType: 'disc' }}> 1 kişiye 1 araba verebiliyoruz.</li>
                    <li style={{ listStyleType: 'disc' }}> 18-65 yaş arası herkese verebiliyoruz.</li>
                    <li style={{ listStyleType: 'disc' }}> Okur yazar olmanız şarttır.</li>
                  </ul>
                </div>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 2}
                index={2}
                onClick={this.handleClick}
              >
                <Icon name='dropdown' />
            Tüm Kredi Kartlarına Taksit İmkanı
        </Accordion.Title>
              <Accordion.Content active={activeIndex === 2}>
                <p>
                  Borz Motor bünyesinde tüm kredi kartlarına 6-9-12 ay taksit imkanı ile araç satış hizmeti sunulmaktadır. Kefil yok, sıra beklemek yok, aracınız anında teslim!
          </p>
              </Accordion.Content>
            </Accordion>
          </PaymentContent>

        </PaymentContainer>
      </React.Fragment>
    )
  }
}
