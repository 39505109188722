import styled from 'styled-components'

const AllCarContainer = styled.div`
    max-width: 1200px;
    margin:auto;
    @media screen and (min-width: 280px) and (max-width:800px){
        .vehicleHeadingView{
            display: none;
        }
    }
`;

const PageTitle = styled.div`

`;

const AllVehicleHeading = styled.div`
    background-color: #ececec;
    .headingInfos{
            ul{
                li{
                    color: #484848;
    text-align: center;
    font-weight: 700;
                }
            }
    }
    @media screen and (min-width: 280px) and (max-width:800px){
               display: none;
            }
`;

const ViewList = styled.div`
margin-top: 15px;
padding-bottom: 10px;
display: flex;
justify-content: flex-end;
.iconCustomListHeader{
    display: flex;
    align-items: center;
    span{
        font-weight: 500!important;
    font-size: 14px!important;
    padding-top: 4px!important;
    margin-right: 5px!important;
    }
    i.red.icon{
        color: #ed4956!important
    }
.iconCustomList{
    font-size: 1em!important;
}
}
`;

const Content = styled.div`
    display:flex;
    .notFoundContent{
        width: 100%;
        background-color: #fbf9bbad;
        padding: 10px;
        margin: 10px;
        border-radius: 4px;
        text-align: center;
        border: 1px #f3eeb7 solid;
    }

        .layoutAllCar{
                &:last-child{
                    width:100%;
                }
/*                 .filtered{
                    margin-bottom: 25px;
                        a{
                            display: flex;
                            box-shadow: 0 5px 3px -3px rgba(0,0,0,.2)!important;
                        }
                        @media screen and (min-width: 280px) and (max-width:800px){
                        margin-bottom: 10px!important;
                        border: none;
                            a{
                                box-shadow: none!important;
                                margin: 0;
                                border-bottom: 1px #ededed solid;
                                max-height: auto;
                                min-height: auto;
                                .listViewImageContainer{
                            &:first-child{
                                width:40%;
                                height: auto;
                                min-height: auto;
                                display: flex;
                                align-items: center;
                                    div{
                                        &:first-child{
                                            img{
                                                max-width: auto;
                                                height: 100%;
                                                width: 100%;
                                            }
                                        }
                                    }      
                            }
                            &:last-child{
                                width: 60%;
                                display: block;
                                margin-bottom:5px!important;
                                div{
                    &:nth-child(1){
                            margin: auto;
                            margin-bottom: 0!important;
                            span{
                                font-size: 16px;
                            }
                    }
                    &:nth-child(2){
                        margin-bottom: auto!important;
    display: block;
    small{
        display: block
    }                            span{
                                font-size: 16px;
                            }
                    }
                    &:nth-child(3){
                        margin-bottom: auto!important;
    display: block;
background-color:auto;
border: auto;
text-align: center
span{
                                font-size: 12px;
                            }
                    }
                    &:nth-child(4){
                        display: flex;
                        margin-bottom: 0!important;
                        span{
                            width:33.33%;
                            margin-bottom:0;
                                &:last-child{
                                    margin-bottom:0;
                                }
                        }

                    }
                }



                            }
                        }
                            }
                }
                } */
        }
        .carList{
            display: grid;
            grid-template-columns: repeat(4, 25%);
            margin-bottom: 10px;
            @media screen and (min-width: 280px) and (max-width:800px){
                grid-template-columns: repeat(1, 100%);
            }
                }
`;

export { AllCarContainer, PageTitle, Content, ViewList, AllVehicleHeading };