import styled from "styled-components";

const VehicleDetailContainer = styled.div``;

const DetailsContainer = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 50px auto;
  position: relative;
  .instaVideoTick {
    bottom: 188px;
    right: 59% !important;
    top: auto !important;
  }
  @media screen and (min-width: 280px) and (max-width: 800px) {
    flex-direction: column;
  }
`;

const InstaVideo = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  position: relative;
  border: none;
  div {
    &:last-child {
      width: 100%;
      height: 100%;
      iframe {
        width: 100% !important;
        margin: auto !important;
        max-width: 100% !important;
        height: 100% !important;
        border: none !important;
      }
    }
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  top: -29px;
  right: -5px;
  transform: translate(-50%, -50%);
  @media screen and (min-width: 280px) and (max-width: 800px) {
    top: -23px;
  }
`;

const OnlyMobileButtons = styled.div`
  .fixedCall {
    position: fixed;
    bottom: -20px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    background-color: #0f84c7;
    color: #fff;
    max-width: 300px;
    border-radius: 4px;
    z-index: 9;
    display: none;
    a {
      color: #fff;
      display: block;
      padding: 15px;
      &:hover {
        color: #fff;
      }
    }
    @media screen and (min-width: 280px) and (max-width: 800px) {
      display: block;
    }
  }
  .instaVideoTick {
    position: absolute;
    right: 69%;
    top: 58%;
    transform: translate(-50%, -50%);
    background-color: #bf0e30;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 1;
    padding: 5px;
    z-index: 99;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
    border: 1px #fff solid;
    cursor: pointer;
    span {
      color: #fff;
      &:first-child {
        font-size: 9px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        i {
          margin: 0;
        }
      }
      /*             &:last-child{
                position: absolute;
                right: -10px;
                top: -10px;
                box-shadow: 0 0 -5px -5px rgba(0,0,0, .4);
                color: orange
            } */
    }
    @media screen and (min-width: 280px) and (max-width: 800px) {
      width: 50px;
      height: 50px;
      position: fixed;
      display: block;
      bottom: -19px !important;
      right: 48px !important;
      top: auto !important;
      span {
        i {
          margin: 3px 0 !important;
        }
      }
    }
  }
`;

export {
  VehicleDetailContainer,
  DetailsContainer,
  InstaVideo,
  CloseIcon,
  OnlyMobileButtons,
};
