import React, { Component } from "react";
import InstagramApi from "../Instagram/index";
import Vehicle from "../Vehicle/index";
import Process from "../Process/index";
import Support from "../Support/index";
import GetFilter from "../GetFilter/index";
import Offer from "../Offer";

export default class HomePage extends Component {
  render() {
    return (
      <React.Fragment>
        <GetFilter />
        <Offer classes="layout" />
        <Vehicle classes="layout" />
        <Process />
        <InstagramApi classes="layout" />
        <Support />
      </React.Fragment>
    );
  }
}
