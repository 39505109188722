import axios from 'axios';
import { API_BASE } from '../config'
import { makeRequest } from '../Utils/Request'

const requestOptions = {
    'Content-Type': 'application/x-www-form-urlencoded'
  };

export const allCarDataCreator = (allCarParams) => {
    return {
        type:'ALLCARDATATYPE',
        allCarParams
    }
}

export const instagramDataCreator = (instagramDataParams) => {
    return {
        type:'INSTAGRAMDATA',
        instagramDataParams
    }
}

export const setServicesCreator = (successData, formResultData) => {
    return {
        type:'SERVICES',
        successData,
        formResultData
    }
}

export const setContactCreator = (successContactData, contactFormResultData) => {
    return {
        type:'CONTACT',
        successContactData,
        contactFormResultData
    }
}

export const getAboutCreator = (aboutParams) => {
    return {
        type:'GETABOUT',
        aboutParams
    }
}

export const carDetailsDataCreator = (carDetailsData, carDetailsDataImages) => {
    return {
        type:'CARDETAILS',
        carDetailsData,
        carDetailsDataImages
    }
}

export const getAllCars = () => {
    let getAllCarsUrl = `${API_BASE}/api/allVehicleViews`;
    return (dispatch) => {
         axios.get(getAllCarsUrl).then((res) => {
                const getAllCarsData = res.data;
                dispatch(allCarDataCreator(getAllCarsData))
                        },
                (err) => {
                    console.log(err);
                }
        )
    }
}

export const saveSaleOffer = (data) => {
    let saveSaleOfferUrl = `${API_BASE}/api/carSaleOffer`;
    return (dispatch) => {
        axios.post(saveSaleOfferUrl, data)
            .then((res) => {
                console.log('ok');
            })
            .catch((error) => {
                console.error('Error while saving sale offer:', error);
            });
    };
};


export const getInstagramPost = () => {
    let instagramPostUrl = `https://www.instagram.com/borzmotor/?__a=1`;
    return (dispatch) => {
         axios.get(instagramPostUrl).then((res) => {
                const instagramPostData = res.data.graphql.user.edge_owner_to_timeline_media.edges;
                dispatch(instagramDataCreator(instagramPostData))
                        },
                (err) => {
                    console.log(err);
                }
        )
    }
}

export const carDetails = (id) => {
    let carDetailsUrl = `${API_BASE}/api/car?_id=${id}`;
    return (dispatch) => {
         axios.get(carDetailsUrl, requestOptions).then((res) => {
                const carDetailsData = res.data.result;
                const carDetailsDataImages = res.data.images;
                dispatch(carDetailsDataCreator(carDetailsData, carDetailsDataImages))
                        },
                (err) => {
                    console.log(err);
                }
        )
    }
}

export const getAbout = () => {
    let aboutUrl = `${API_BASE}/api/getAbout`;
    return (dispatch) => {
         axios.get(aboutUrl).then((res) => {
                const aboutData = res.data;
                dispatch(getAboutCreator(aboutData))
                        },
                (err) => {
                    console.log(err);
                }
        )
    }
}

export const setCarServices = ({ sellingType, brand, model, year, kilometers, fuel, gear, paintPiece, changingTrack, accident, nameSurname, phone, email, message }) => {

    let servicesUrl = `${API_BASE}/api/services`;
    let urlencoded = new URLSearchParams();
    urlencoded.append('sellingType', sellingType);
    urlencoded.append('brand', brand);
    urlencoded.append('model', model);
    urlencoded.append('year', year);
    urlencoded.append('kilometers', kilometers);
    urlencoded.append('fuel', fuel);
    urlencoded.append('gear', gear);
    urlencoded.append('paintPiece', paintPiece);
    urlencoded.append('changingTrack', changingTrack);
    urlencoded.append('accident', accident);
    urlencoded.append('nameSurname', nameSurname);
    urlencoded.append('phone', phone);
    urlencoded.append('email', email);
    urlencoded.append('message', message);

    return (dispatch) => {
        makeRequest('POST', servicesUrl, urlencoded, requestOptions).then((res) => {
                const servicesData = JSON.parse(res);
                const successData = servicesData.success;
                const formResultData = servicesData.formResult;
                dispatch(setServicesCreator(successData, formResultData))
                },
                (err) => {
                console.log(err);
                }
            )}
}

export const setContact = ({ name, subject, email, message }) => {

    let contactUrl = `${API_BASE}/api/contact`;
    let urlencoded = new URLSearchParams();

    urlencoded.append('name', name.toUpperCase());
    urlencoded.append('subject', subject.toUpperCase());
    urlencoded.append('email', email.toUpperCase());
    urlencoded.append('message', message.toUpperCase());

    return (dispatch) => {
        makeRequest('POST', contactUrl, urlencoded, requestOptions).then((res) => {
                const contactData = JSON.parse(res);
                const successContactData = contactData.success;
                const contactFormResultData = contactData.formResult;
                dispatch(setContactCreator(successContactData, contactFormResultData))
                },
                (err) => {
                console.log(err);
                }
            )}
}