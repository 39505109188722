import React, { Component } from 'react'
import { InputGroup, ContactHeading } from './style'
import ContactComponent from '../../Components/Contact/Loadable'
import Input from '../../Components/FormElements/Input/Loadable'
import Textarea from '../../Components/FormElements/TextArea/Loadable'
import Button from '../../Components/FormElements/Button/Loadable'
import Swal from 'sweetalert2'

import { connect } from 'react-redux'
import { setContact } from '../../Actions'

class Contact extends Component {
    constructor(props){
        super(props)
        this.state={
            name: '',
            email: '',
            subject: '',
            message: '',
        }
        this.contactFormSubmit=this.contactFormSubmit.bind(this)
        this.handleTextChange=this.handleTextChange.bind(this)
    }

    contactFormSubmit(e){
        e.preventDefault();
        this.props.setContact(this.state);
        Swal.fire(
            'Başarılı',
            'Mesajınız Bize Ulaştı',
            'success'
          )
        setTimeout(() => {
            window.location.reload()
        }, 1500);
    }

    handleTextChange(e){
        const target = e.target;
        const name = target.name;
        const value = target.value;

        this.setState({
            [name]: value
        });
    }

    render() {
        return (
            <div>
                <ContactComponent>
                        <ContactHeading>
                            <span>Görüş ve Önerileriniz Bizim İçin Önemli</span>
                            <p>Yeni nesil galericiliğin öncü temsilcilerinden Borz Motor'a aşağıdaki iletişim adreslerinden ulaşabilir ve ya showroomumuza gelip size en uygun arabayı seçmek için
                            <br /> uzman kadromuza başvurabilirsiniz.</p>
                        </ContactHeading>
                    <form onSubmit={ this.contactFormSubmit }>
                        <InputGroup>
                            <Input required type="text" placeholder="İsim" name="name" onChange={(e) => this.handleTextChange(e)}/>
                        </InputGroup>
                        <InputGroup>
                            <Input required type="email" placeholder="e-mail" name="email" onChange={(e) => this.handleTextChange(e)}/>
                        </InputGroup>
                        <InputGroup>
                            <Input required type="text" placeholder="konu" name="subject" onChange={(e) => this.handleTextChange(e)}/>
                        </InputGroup>
                        <InputGroup>
                            <Textarea required placeholder="mesajınız" name="message" onchange={(e) => this.handleTextChange(e)}/>
                        </InputGroup>
                        <InputGroup style={{ textAlign: 'center' }}>
                            <Button label="gönder" type="submit"/>
                        </InputGroup>
                    </form>
                </ContactComponent>
            </div>
        )
    }
}

export default connect((state)=>{return state},{ setContact })( Contact );
