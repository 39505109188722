/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-constructor */
import React, { Component } from "react";
import {
  VehicleDetailContainer,
  DetailsContainer,
  InstaVideo,
  CloseIcon,
  OnlyMobileButtons,
} from "./style";
import FancyBox from "../../Components/FancyBox";
import CarContentInfo from "../../Components/CarContentInfo/Loadable";
import CarDetailsInfo from "../../Components/CarDetailsInfo/Loadable";
import IntroInfo from "../../Components/CarIntroInfo/Loadable";
import { API_BASE } from "../../config";
import { connect } from "react-redux";
import { carDetails } from "../../Actions";
import ReactHtmlParser from "react-html-parser";
import placeholderImage from "../../Images/placeholder-single.png";
import _ from "lodash";
import { Modal, Icon, Header } from "semantic-ui-react";
import InstagramCardComponent from "../../Components/InstagramCard/Loadable";
class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      video: false,
    };
    this.handleVideo = this.handleVideo.bind(this);
    this.paintingView = this.paintingView.bind(this);
    this.goBack = this.goBack.bind(this);
  }
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.carDetails(id);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.carDetailsData.images !== this.props.carDetailsData.images) {
      const { images } = this.props.carDetailsData;
      if (images && images.length > 0) {
        let imagesAll = [];
        for (let i = 0; i < images.length; i++) {
          imagesAll.push({
            original: `${API_BASE}/${images && images[i].name}`,
            thumbnail: `${API_BASE}/${images && images[i].name}`,
          });
        }
        this.setState({
          images: imagesAll,
        });
      }
    }
  }

  handleVideo() {
    const { video } = this.state;
    this.setState({ video: !video });
  }

  getUrl() {
    return "https://www.borzmotor.com" + this.props.match.url;
  }

  goBack() {
    this.props.history.goBack();
  }

  paintingView() {
    const { painting } = this.props.carDetailsData;
    let dataValue = "";
    if (painting && painting) {
      dataValue = Object.entries(painting).map((key, value) => {
        return (
          <li key={value}>
            <span className="tramerKeywords">{_.startCase(key[0])}</span>
            <span className="tramerValues">{key[1]}</span>
          </li>
        );
      });
    }
    return dataValue;
  }

  render() {
    const { images } = this.state;
    const {
      intro,
      price,
      date,
      brand,
      model,
      year,
      gear,
      fuel,
      kilometers,
      kasko,
      city,
      responsible,
      responsiblePhone,
      province,
      settings,
      motor,
      box,
      description,
      tramer,
      instaVideo,
    } = this.props.carDetailsData;
    const { video } = this.state;
    return (
      <VehicleDetailContainer>
        {video && instaVideo && (
          <Modal
            style={{ width: "100%", height: "100%" }}
            open={video}
            onClose={this.handleVideo}
          >
            <Modal.Header>Aracın Test Sürüşü Videosu</Modal.Header>
            <InstaVideo>
              <CloseIcon onClick={this.handleVideo}>
                <Icon size="big" name="close" />
              </CloseIcon>
              <InstagramCardComponent instaVideo={instaVideo} />
            </InstaVideo>
          </Modal>
        )}
        <IntroInfo>
          <button
            className="col col-1 m-0 p-0"
            style={{ border: "none", backgroundColor: "transparent" }}
            onClick={this.goBack}
          >
            <Header>
              <Icon
                size="large"
                className="backIcon"
                color="brown"
                name="arrow left"
              />
            </Header>
          </button>
          <a
            className="sharedWhatsapp col col-1 m-0 p-0"
            href={`whatsapp://send?text=${this.getUrl()}`}
            data-action="share/whatsapp/share"
          >
            <i
              title="Bu ilanı Whatsappta paylaş!"
              aria-hidden="true"
              className="green whatsapp large icon backIcon"
            ></i>
          </a>
          <div className="introInfoDetails col col-10 m-0">
            <span style={{ textTransform: "capitalize" }}>
              {intro && intro}
            </span>
            <span>{price && price + " " + "₺"}</span>
          </div>
        </IntroInfo>
        <DetailsContainer style={{ margin: "0 auto" }}>
          {images && images.length > 0 ? (
            <React.Fragment>
              <OnlyMobileButtons>
                <div
                  className="fixedCall"
                  style={
                    instaVideo
                      ? { width: "50%", left: "40%" }
                      : { width: "100%" }
                  }
                >
                  <a href="tel:+905322058058">ARA</a>
                </div>
                {!video && instaVideo && instaVideo && (
                  <div className="instaVideoTick" onClick={this.handleVideo}>
                    <span>
                      <Icon size="big" name="car" />
                      Video
                    </span>
                  </div>
                )}
              </OnlyMobileButtons>
              <FancyBox images={images} />
            </React.Fragment>
          ) : (
            <img
              style={{ borderRadius: "4px" }}
              src={placeholderImage}
              alt="görsel bulunamadı"
            />
          )}
          <CarContentInfo
            date={date}
            brand={brand}
            motor={motor}
            box={box}
            model={model}
            year={year}
            gear={gear}
            fuel={fuel}
            kasko={kasko}
            kilometers={kilometers}
            city={city}
            province={province}
            responsible={responsible}
            phone={responsiblePhone}
          />
        </DetailsContainer>
        <DetailsContainer>
          <CarDetailsInfo
            tramerAmount={tramer}
            tramerOptions={this.paintingView()}
            settings={ReactHtmlParser(settings)}
            description={ReactHtmlParser(description)}
          />
        </DetailsContainer>
      </VehicleDetailContainer>
    );
  }
}
export default connect(
  (state) => {
    return state;
  },
  { carDetails }
)(Details);
