/* eslint-disable no-useless-constructor */
import React, { Component } from "react";
import AboutUs from "../../Components/About/Loadable";

import { connect } from "react-redux";
import { getAbout } from "../../Actions";

class About extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.getAbout();
  }

  render() {
    const { about, vision, mission } = this.props.aboutParams || {};
    return (
      <React.Fragment>
        {this.props.aboutParams ? (
          <AboutUs about={about} vision={vision} mission={mission} />
        ) : (
          <AboutUs
            about="İçerik Bulunamadı"
            vision="İçerik Bulunamadı"
            mission="İçerik Bulunamadı"
          />
        )}
      </React.Fragment>
    );
  }
}
export default connect(
  (state) => {
    return state;
  },
  { getAbout }
)(About);
