import styled from 'styled-components'

const InputGroup = styled.div`

`;

const ContactHeading = styled.div`
    margin: 10px 0 20px 0;
        span{
            font-weight: 700;
            display:block;
            margin-bottom:1rem;
        }
        p{
            color: #828282;
            font-size: 13px;
        }
`;

export { InputGroup, ContactHeading }