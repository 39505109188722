/* eslint-disable no-useless-constructor */
import React, { Component } from 'react'
//import AllCarFilter from '../../Components/CarDetailFilter/Loadable'
import VehicleCard from '../../Components/VehicleCard/Loadable'
import { AllCarContainer, Content, ViewList, AllVehicleHeading } from './style'
/* import BreadCrumb from '../../Components/BreadCrumb/Loadable' */
import placeholderImage from '../../Images/placeholder-single.png'
import { API_BASE } from '../../config'
import { Icon, Header } from 'semantic-ui-react'
import { isBrowser } from "react-device-detect";
import { connect } from 'react-redux';
import { getAllCars } from "../../Actions";

class AllCars extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carList: (!isBrowser) || (window.innerWidth >= 2000) ? true : false
    }
    this.list = this.list.bind(this)
  }

  componentDidMount() {
    this.props.getAllCars()
  }

  list() {
    const { carList } = this.state;
    this.setState({ carList: !carList })
  }

  render() {
    const { allCarParams } = this.props;
    const { carList } = this.state;
    return (
      <AllCarContainer className="container-fluid">
        <ViewList className="row" onClick={this.list}> {
          <Header className="iconCustomListHeader"><span>{carList ? "Liste" : "Galeri"} Görünümü</span> <Icon name='list layout' className="iconCustomList" color={carList ? "red" : "grey"} /></Header>
        } </ViewList>
        {
          carList &&
          <AllVehicleHeading className="row p-2 vehicleHeadingView mb-2">
            <div className="headingEmpty col col-5 col-xl-3 col-lg-3 col-md-3 col-sm-5 pr-0 col-xs-6"></div>
            <div className="headingInfos col col-7 col-xl-9 col-lg-9 col-md-9 col-sm-7 col-xs-6 m-0 p-0">
                <ul className="row m-0">
                  <li className="col-md-3 p-0">Marka - Model</li>
                  <li className="col-md-3 p-0">Fiyat</li>
                  <li className="col-md-3 p-0">Açıklama</li>
                  <li className="col-md-3 p-0">Özellikler</li>
                </ul>
            </div>
          </AllVehicleHeading>
        }
        <Content className="row pt-2 pb-2 pl-0 pr-0">
          {
            allCarParams && allCarParams.length > 0 ?
              <React.Fragment>
                {
                  allCarParams && allCarParams.map(({ _id, brand, published, model, kilometers, price, fuel, gear, intro, images }) => (
                    <VehicleCard
                    target="_self"
                      key={_id}
                      href={'/arac-detay/' + _id}
                      id={_id}
                      listViewImage={carList && carList ? 'listViewImageContainer' : ''}
                      filterClass={carList && carList ? 'filtered' : ''}
                      published={published && published ? 'İlan Yayında' : 'İlan Yayında Değil'}
                      brand={brand}
                      model={model}
                      intro={intro}
                      price={price}
                      km={kilometers}
                      fuel={fuel}
                      gear={gear}
                      images={
                        images && images.length > 0 ?
                          <div><img src={API_BASE + "/" + images[0].name} alt="" /></div>
                          :
                          <div><img src={placeholderImage} alt="" /></div>
                      }
                    />
                  ))
                }
              </React.Fragment>
              : <div className="notFoundContent"><span>İçerik Bulunamadı!</span></div>
          }
        </Content>

      </AllCarContainer>
    )
  }
}
export default connect((state) => { return state }, { getAllCars })(AllCars)
